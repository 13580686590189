<template>
  <div class="backdrop">
    <div class="b-modal" v-if="data">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">View {{data.businessfileId}} </h5>
            <p @click="closeModal()" class="m-2 x">x</p>
        </div>
      <div class="modalBody" v-if="state == 'init'">

        <div v-if="isLoading" class="loading-spinner"></div>
          <div v-if="!isLoading">
            <button class="btn btn-secondary" @click="download(data)">Download</button>
            <div v-if="item.fileFormat == 'pdf'">
              <embed :src="`data:application/pdf;base64,${this.imgUpload}`" width="600" height="500" alt="PDF" >
            </div>
            <div v-else>
              <img :src="`data:image/png;base64,${this.imgUpload}`"  class="image"   />
            </div>
          </div>

        
        <table v-if="!isLoading && data.status != 'SUBMITTED'" class="table table-responsive">
          <thead>
            <tr>
                <th>Date Processed</th>
                <th>Processed by</th>
                <th>Status</th>
                <th>Remarks</th>
            </tr>
            </thead>
            <tbody>
                 <td >{{formatDate(data.updateDate)}}</td>
                 <td>{{data.processedBy}}</td>
                 <td>{{data.status}}</td>
                 <td>{{data.remarks}}</td>
               
            </tbody>

        </table>

        
       
          <div class="d-flex" v-if="!isLoading && data.status == 'SUBMITTED'">
            <button class="btn btn-main" @click="approve()" :disabled="!init.FXAPPROVERONE || !init.FXAPPROVERTWO">
              Approve?
            </button>
           
            <button class="btn btn-main" @click="reject()" :disabled="!init.FXAPPROVERONE || !init.FXAPPROVERTWO">
              Reject?
            </button>
            <button class="btn btn-main" @click="remove()" :disabled="!init.FXDELETE">
                Archive File
            </button>
          </div>
          <div class="d-flex" v-if="!isLoading && data.status == 'REJECT'">
           
            <button class="btn btn-main" @click="remove()" :disabled="!init.FXDELETE">
                Archive File
            </button>
          </div>
      </div>
      <div class="modalBody" v-if="state == 'confirmDelete' ">
          <div >
            <p style="color: rgb(199, 71, 71);" v-if="showErrorMsg">{{errorMsg}}</p>
            <h5 class="text-center">Are you sure you want to ARCHIVE this document? </h5>
         
            
            <div class="d-flex">
              <button class="btn btn-main" @click="cancel()">
                CANCEL
               </button>
              <button class="btn btn-main" @click="confirmDelete()">
                Confirm ARCHIVE
               </button>
              
            </div>
            
          </div>
      </div>
      <div class="modalBody" v-if="state == 'confirmApprove' ">
          <div >
            <p style="color: rgb(199, 71, 71);" v-if="showErrorMsg">{{errorMsg}}</p>
            <h5 class="text-center">Are you sure you want to APPROVE this document? </h5>
            <div class="text-center">
              <h5>Please enter remarks/ comments: *required</h5>
              <textarea v-model="comment" class="form-control"></textarea>
            </div>
            
            <div class="d-flex">
              <button class="btn btn-main" @click="cancel()">
                CANCEL
               </button>
              <button class="btn btn-main" @click="confirmApproved()">
                Confirm APPROVE
               </button>
              
            </div>
            
          </div>
      </div>
      <div class="modalBody" v-if="state == 'confirmReject'">
        <div >
          <p style="color: rgb(199, 71, 71);" v-if="showErrorMsg">{{errorMsg}}</p>
          <h5 class="text-center">Are you sure you want to REJECT this document? </h5>
          <div class="text-center">
            <h5>Please enter remarks/ comments: *required</h5>
            <textarea v-model="comment" class="form-control"></textarea>
          </div>
          <div class="d-flex">
            <button class="btn btn-main" @click="cancel()">
              CANCEL
             </button>
            <button class="btn btn-main" @click="confirmRejected()">
              Confirm REJECT
            </button>
           
          </div>
         
        </div>
    </div>
    <div class="modalBody" v-if="state == 'accepted'">
      <div >
        <h5 class="text-center">You have APPROVED this document. </h5>
        <div class="d-flex">
          <button class="btn btn-main" @click="closeModal()">
            CLOSE
           </button>
        </div>
      </div>
  </div>
  <div class="modalBody" v-if="state == 'deleted'">
      <div >
        <h5 class="text-center">You have ARCHIVED this document.</h5>
        <div class="d-flex">
          <button class="btn btn-main" @click="closeModal()">
            CLOSE
           </button>
        </div>
      </div>
  </div>
  <div class="modalBody" v-if="state == 'rejected'">
    <div >
      <h5 class="text-center">You have REJECTED this document.</h5>
      <div class="d-flex">
        <button class="btn btn-main" @click="closeModal()">
          CLOSE
         </button>
      </div>
    </div>
</div>
     
     
    
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'

export default {
    props:["data", "format"],
    components: {

    },
    data: function() {
        return {
            state:'init',
            imgUpload:'',
            isLoading: false,
            comment:'',
            showErrorMsg: false,
            errorMsg: '',
            image:'',
            fileType:'',
            imageUrl:'',
            item:{
              fileFormat:''
            }
           
            
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
     
      },
    methods: {
      showUpload(){
        this.item.fileFormat = this.data.imageFile.split('.').pop();
        
        this.isLoading = true;
        const params = {
            // entityId: this.$route.params.entityId
            entityId: this.data.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/image/' + this.data.businessfileId,
              }
              this.axios(options)
              .then((response) => {
              
               this.isLoading = false;
               this.imgUpload = response.data
                //  if(this.data.fileType === 'pdf'){
                //   this.imgUpload = "data:application/pdf;base64," + response.data
                // }

                // if(this.data.fileType !== 'pdf'){
                //  this.imgUpload = response.data
                // }
                
              }).catch((err) => {
                  
              })
      },
      formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
      download(data){
        this.imageUrl = data.imageFile
        this.fileType = this.imageUrl.split('.').pop();

           const params = {
            entityId: this.data.entityId
         }
           
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                params,
                url: '/ax/customer/businessfile/image/' + data.businessfileId,
                }
                this.axios(options)
                .then((response) => {
                  
                  
                if(this.fileType === 'pdf'){
                  const link = document.createElement('a');
                  link.href = "data:application/pdf;base64," + response.data
                  link.download =  this.imageUrl ; 
                  link.click();
                }

                if(this.fileType !== 'pdf'){
                  const link = document.createElement('a');
                  link.href = "data:image/png;base64," + response.data
                  link.download =  this.imageUrl ; 
                  link.click();
                }
               
                
                }).catch((err) => {
                    
                })
      },

      remove(){
        this.state = "confirmDelete"
      },
      approve(){
        this.state = "confirmApprove"
      },
      reject(){
        this.state = "confirmReject"
      },
      confirmApproved(){
        let hasError = false;
        if(this.comment == '' || this.comment == undefined || this.comment == null){
          this.showErrorMsg = true
          this.errorMsg = 'Comment/ Remarks is required.'
          hasError = true
          setTimeout(()=>{
            this.showErrorMsg = false,
            this.errorMsg = ''
          }, 2000)
        }

        if(!hasError){
          const params = {
          businessfileId: this.data.businessfileId,
          remarks: this.comment
        }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/approve',
              }
              this.axios(options)
              .then((response) => {
               console.log(response.data)
                this.state = 'accepted'
          
                
              }).catch((err) => {
                  
              })
        }
      },
      confirmDelete(){
       
          const params = {
          businessfileId: this.data.businessfileId,
        }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/delete',
              }
              this.axios(options)
              .then((response) => {
               console.log(response.data)
                this.state = 'deleted'
          
                
              }).catch((err) => {
                  
              })
      
        
        
      },
      confirmRejected(){
        let hasError = false;
        if(this.comment == '' || this.comment == undefined || this.comment == null){
          this.showErrorMsg = true
          this.errorMsg = 'Comment/ Remarks is required.'
          hasError = true
          setTimeout(()=>{
            this.showErrorMsg = false,
            this.errorMsg = ''
          }, 2000)
        }

        if(!hasError){
          const params = {
          businessfileId: this.data.businessfileId,
          remarks: this.comment
        }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/reject',
              }
              this.axios(options)
              .then((response) => {
               console.log(response.data)
                this.state = 'rejected'
          
                
              }).catch((err) => {
                  
              })
        }
        
        
      },
      cancel(){
        this.state='init'
      },
        closeModal(){
        this.$emit("close")
        window.location.reload();
        },

       
      
    },
  
    mounted(){
        this.showUpload();
        
        
    }
}
</script>

<style lang=scss scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 20px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

    color:black;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.userPic{
    width: 10rem;
}
.image{
  width: 100%;
}
.img img{
    width: 100%;
    border-radius: 10px;;
     box-shadow: 0 2px 2px rgba(50,50,50,.50);
     border:7px solid #fff;
     border-radius: 10px;;
     background-color: white;
      background-size:cover;
      object-fit: contain;
  }
table th{
  font-size: 17px;
}
  .info{
    width: 100%;
    margin-left: 5rem;
    padding-left:1rem;
      .info .name{
      padding: 0.5rem 0 2rem 0;
        .info .name h3{
        font-size: 1rem;
        line-height:110%;
        font-weight: 400;
          .info .name p{
          font-size:0.9rem;
          color: black;
          }
        }
      }
    .info .desc .soc-med{
      display: flex !important;
      flex-direction: row;
      justify-content: space-evenly;
      font-size: 15px !important;
    }
  }
.profile-card {
  height: auto !important;
  width: 100%;
  margin: auto 10px;
  background: Orgba(255,255,255,0.2);
  box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6);
  border: 4px white solid;
  padding: 5px;
  border-radius: 12px;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
    &:hover{
    animation-name: bounce;
    animation-timing-function: ease;

    }

}
 

.iconImg {
    width:50%;
}
 .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        border: 1px solid #481a69;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>